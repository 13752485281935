import React from 'react'
import CategoryNav from '../components/categories/nav'
import Logo from '../components/layout/logo'
import { Container, Row, Col } from 'react-bootstrap'

class FourOhFourPage extends React.Component {
  render() {
    return (
      <>
        <Container className="py-5">
          <Row className="align-items-center">
            <Col md="3" className="pb-3 pb-md-0">
              <Logo />
            </Col>
            <Col md={{ span: 7, offset: 2 }}>
              <h5 className="mb-3 text-secondary">404 - Page Not Found</h5>
              <h3 className="hu h4 mb-3">Oops! That page doesn't exist.</h3>
              <p>Please check the address and try again.</p>
            </Col>
          </Row>
        </Container>
        <CategoryNav />
      </>
    )
  }
}

export default FourOhFourPage
